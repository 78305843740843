import React from 'react';
import { Admin, Resource, ShowGuesser } from 'react-admin';
import { ToastProvider } from 'react-toast-notifications';

import authProvider from '../../common/authProvider';
import { createTransformedDataProvider, dataProvider } from '../../common/http';
import { ActivityList, ActivityShow } from '../../models/activities/activities';
import { ApiUserCreate, ApiUserEdit, ApiUserShow, ApiUsersList } from '../../models/api-users/api-users';
import {
  AttractionDefaultCreate,
  AttractionDefaultEdit,
  AttractionDefaultList,
  AttractionDefaultShow,
} from '../../models/attractions/attractionDefault';
import {
  AttractionsCreate,
  AttractionsEdit,
  Attractionshow,
  AttractionsList,
  attractionsTransformations,
} from '../../models/attractions/attractions';
import { BadgeCreate, BadgeEdit, BadgeList, BadgeShow } from '../../models/badges/badges';
import { CampaignCreate, CampaignEdit, CampaignList, CampaignShow } from '../../models/campaigns/campaigns';
import { CodesCreate, CodesEdit, Codeshow, CodesList, codeTransformations } from '../../models/codes/codes';
import { DatalistEdit, DatalistList, DatalistShow } from '../../models/datalist/datalist';
import { DonationCreate, DonationEdit, DonationList, DonationShow } from '../../models/donations/donations';
import { FavoriteCreate, FavoriteEdit, Favoritehow, FavoriteList } from '../../models/favorite/favorite';
import { ParticipationCreate, ParticipationEdit, ParticipationList, ParticipationShow } from '../../models/participation/participation';
import { PaymentItemsCreate, PaymentItemsEdit, PaymentItemsShow } from '../../models/payments/payment-items';
import { PaymentsCreate, PaymentsEdit, PaymentsList, PaymentsShow } from '../../models/payments/payments';
import { PrizeWinnerEdit, PrizeWinnerList, PrizeWinnerShow } from '../../models/prize-winners/prize-winners';
import { PrizeCreate, PrizeEdit, PrizeList, PrizeShow, prizeTransformations } from '../../models/prizes/prizes';
import { ProgresssList } from '../../models/progress/progress';
import { RankingItemsShow } from '../../models/ranking/ranking-items';
import { RankingsCreate, RankingsEdit, RankingsList, RankingsShow } from '../../models/ranking/rankings';
import { RoomCreate, RoomEdit, RoomList } from '../../models/room/rooms';
import { RoomShow } from '../../models/room/rooms-show';
import { RoomAdditionalDataCreate, RoomAdditionalDataEdit } from '../../models/roomAdditionalData/room-additional-data';
import { SegmentCreate, SegmentEdit, SegmentList, SegmentShow } from '../../models/segments/segments';
import { TenantCreate, TenantEdit, TenantList, TenantShow } from '../../models/tenants/tenants';
import { UserCreate, UserEdit, UserShow, UsersList } from '../../models/users/users';
import Login from '../Login/Login';

const patchedProvider = createTransformedDataProvider(dataProvider, [
  prizeTransformations,
  attractionsTransformations,
  codeTransformations,
]);

class App extends React.Component {
  render() {
    return (
      <ToastProvider>
        <Admin loginPage={Login} dataProvider={patchedProvider} authProvider={authProvider} title="viRACE Admin">
          {(permissions: string) => [
            <Resource name="user" list={UsersList} edit={UserEdit} show={UserShow} create={UserCreate} />,
            isSuperadmin(permissions) ? <Resource name="progress" list={ProgresssList} show={ShowGuesser} /> : null,
            <Resource
              name="participation"
              list={ParticipationList}
              edit={ParticipationEdit}
              show={ParticipationShow}
              create={ParticipationCreate}
            />,
            <Resource options={{ label: 'Races' }} name="room" list={RoomList} edit={RoomEdit} create={RoomCreate} show={RoomShow} />,
            <Resource name="roomAdditionalData" create={RoomAdditionalDataCreate} edit={RoomAdditionalDataEdit} />,
            <Resource
              options={{ label: 'Segments' }}
              name="segment"
              list={SegmentList}
              edit={SegmentEdit}
              create={SegmentCreate}
              show={SegmentShow}
            />,
            isSuperadmin(permissions) ? (
              <Resource name="favorite" list={FavoriteList} show={Favoritehow} edit={FavoriteEdit} create={FavoriteCreate} />
            ) : null,
            <Resource name="attraction" list={AttractionsList} show={Attractionshow} edit={AttractionsEdit} create={AttractionsCreate} />,
            isSuperadmin(permissions) ? (
              <Resource
                name="attractionDefault"
                options={{ label: 'Attraction Defaults' }}
                list={AttractionDefaultList}
                edit={AttractionDefaultEdit}
                show={AttractionDefaultShow}
                create={AttractionDefaultCreate}
              />
            ) : null,
            isSuperadmin(permissions) ? (
              <Resource name="prize" list={PrizeList} show={PrizeShow} edit={PrizeEdit} create={PrizeCreate} />
            ) : null,
            isSuperadmin(permissions) ? (
              <Resource
                options={{ label: 'Prize Winners' }}
                name="prize-winner"
                list={PrizeWinnerList}
                show={PrizeWinnerShow}
                edit={PrizeWinnerEdit}
              />
            ) : null,
            isSuperadmin(permissions) ? (
              <Resource name="badge" list={BadgeList} show={BadgeShow} edit={BadgeEdit} create={BadgeCreate} />
            ) : null,
            <Resource name="badgeUser" />,
            isSuperadmin(permissions) ? (
              <Resource name="codes" list={CodesList} edit={CodesEdit} show={Codeshow} create={CodesCreate} />
            ) : null,
            isSuperadmin(permissions)
              ? [
                  <Resource name="payment" list={PaymentsList} show={PaymentsShow} edit={PaymentsEdit} create={PaymentsCreate} />,
                  <Resource name="payment-item" show={PaymentItemsShow} edit={PaymentItemsEdit} create={PaymentItemsCreate} />,
                ]
              : null,
            isSuperadmin(permissions)
              ? [
                  <Resource name="ranking" list={RankingsList} show={RankingsShow} edit={RankingsEdit} create={RankingsCreate} />,
                  <Resource name="ranking-item" show={RankingItemsShow} />,
                ]
              : null,
            isSuperadmin(permissions) ? (
              <Resource name="donation" list={DonationList} show={DonationShow} edit={DonationEdit} create={DonationCreate} />
            ) : null,
            isSuperadmin(permissions) ? (
              <Resource name="campaign" list={CampaignList} show={CampaignShow} edit={CampaignEdit} create={CampaignCreate} />
            ) : null,
            isSuperadmin(permissions) ? (
              <Resource
                options={{ label: 'ApiUsers' }}
                name="api-user"
                list={ApiUsersList}
                edit={ApiUserEdit}
                show={ApiUserShow}
                create={ApiUserCreate}
              />
            ) : null,
            isSuperadmin(permissions) ? <Resource name="datalist" list={DatalistList} show={DatalistShow} edit={DatalistEdit} /> : null,
            isSuperadmin(permissions) ? <Resource name="activity" list={ActivityList} show={ActivityShow} /> : null,
            isSuperadmin(permissions) ? <Resource name="activity-room-match" /> : null,
            isSuperadmin(permissions) ? (
              <Resource
                options={{ label: 'Tenants' }}
                name="tenant"
                list={TenantList}
                edit={TenantEdit}
                show={TenantShow}
                create={TenantCreate}
              />
            ) : (
              <Resource name="tenant" />
            ),
          ]}
        </Admin>
      </ToastProvider>
    );
  }
}

const isSuperadmin = (permissions: string) => {
  return permissions === 'superadmin';
};

export default App;
